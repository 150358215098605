import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "json-view-item" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["aria-expanded"]
const _hoisted_4 = { class: "properties" }
const _hoisted_5 = ["id", "role", "tabindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JsonTreeViewItem = _resolveComponent("JsonTreeViewItem", true)!
  const _component_ElTooltip = _resolveComponent("ElTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.data.type === _ctx.ItemType.OBJECT || _ctx.data.type === _ctx.ItemType.ARRAY)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "data-key",
            "aria-expanded": _ctx.state.open ? 'true' : 'false',
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.toggleOpen(_ctx.data)), ["stop"]))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.classes)
            }, null, 2),
            _createTextVNode(" " + _toDisplayString(_ctx.data.key !== "/" ? _ctx.data.key : "") + " ", 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.lengthString), 1)
          ], 8, _hoisted_3),
          (_ctx.state.open)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.data.children, (child) => {
                return (_openBlock(), _createBlock(_component_JsonTreeViewItem, {
                  key: _ctx.getKey(child),
                  data: child,
                  maxDepth: _ctx.maxDepth,
                  canSelect: _ctx.canSelect,
                  objectMap: _ctx.objectMap,
                  tab: _ctx.tab,
                  path: _ctx.path,
                  element: _ctx.element,
                  onSelected: _ctx.bubbleSelected,
                  "click-able-data-type": _ctx.clickAbleDataType
                }, null, 8, ["data", "maxDepth", "canSelect", "objectMap", "tab", "path", "element", "onSelected", "click-able-data-type"]))
              }), 128))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.data.type === _ctx.ItemType.VALUE)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: 'item-'+ _ctx.data.path + '-' + _ctx.tab,
          class: _normalizeClass([_ctx.valueClasses, _ctx.matchedElements(_ctx.data) ? 'match':'']),
          role: _ctx.canSelect ? 'button' : undefined,
          tabindex: _ctx.canSelect ? '0' : undefined,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick(_ctx.data))),
          onKeyup: [
            _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.onClick(_ctx.data)), ["enter"])),
            _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.onClick(_ctx.data)), ["space"]))
          ],
          style: _normalizeStyle({ backgroundColor : _ctx.getKeyColor(_ctx.data.path)})
        }, [
          _createVNode(_component_ElTooltip, {
            effect: "custom",
            content: _ctx.data.value,
            placement: "left"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.setClass(_ctx.tab)),
                contenteditable: "false"
              }, _toDisplayString(_ctx.data.key), 3)
            ]),
            _: 1
          }, 8, ["content"])
        ], 46, _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}