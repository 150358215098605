
import {
  computed,
  defineComponent,
  PropType,
  reactive, ref,
  SetupContext,
} from "vue";
import { then, when } from "switch-ts";
import { ElTag, ElTooltip } from "element-plus";

export interface SelectedData {
  key: string;
  value: string;
  path: string;
}

export interface Data {
  [key: string]: string;
}

export enum ItemType {
  OBJECT,
  ARRAY,
  VALUE,
}

export type ValueTypes =
    | unknown
    | string
    | number
    | bigint
    | boolean
    | undefined;

export type ItemData = {
  key: string;
  type: ItemType;
  path: string;
  depth: number;
  length?: number;
  children?: ItemData[];
  value?: ValueTypes;
  parent? : ItemData;
};

type Props = {
  data: ItemData;
  maxDepth: number;
  canSelect: boolean;
  objectMap: Map<any, any>;
  tab: number;
  path: string;
  element: SelectedElement;
  clickAbleDataType: "normal" | "branched"
};

export type SelectedElement = {
  key: string;
  value: string;
}

export default defineComponent({
  name: "JsonTreeViewItem",
  props: {
    data: {
      required: true,
      type: Object as PropType<ItemData>,
    },
    maxDepth: {
      type: Number,
      required: false,
      default: 1,
    },
    canSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    objectMap: {
      type: Map,
      required: false,
      default: new Map(),
    },
    tab: {
      type: Number,
      required: false,
      default: 1,
    },
    path: {
      type: String,
      required: false,
      default: "path",
    },
    element: {
      type: Object as PropType<SelectedElement>,
      required: false,
      default: {
        key: "key",
        value: "value",
      }
    },
    clickAbleDataType: {
      type: String,
    }
  },
  components: {
    ElTag,
    ElTooltip
  },
  setup(props: Props, context: SetupContext) {
    const state = reactive({
      open: props.data.depth < props.maxDepth,
    });

    const click = ref(0);
    let timer = ref();

    function toggleOpen(data): void {
      click.value++;
      if (click.value === 1) {
        timer.value = setTimeout(() => {
          state.open = !state.open;
          click.value = 0;
        }, 200);
      } else {
        clearTimeout(timer.value);
        click.value = 0;
        let path = removeAtFromPath(data.path);
        if (data.type === ItemType.ARRAY && data.parent.type === ItemType.OBJECT) {
          context.emit("selected", {
            key: data.key,
            path: `${path}${data.key}`,
            value: "",
          });
        } else if (data.type === ItemType.ARRAY && data.parent.type === ItemType.ARRAY) {
          context.emit("selected", {
            key: data.key,
            path: `${path}`,
            value: "",
          });
        } else if (data.type === ItemType.OBJECT && data.depth !== 0 && data.parent.type === ItemType.OBJECT) {
          context.emit("selected", {
            key: data.key,
            path: `${path}${data.key}`,
            value: "",
          });
        } else if (data.type === ItemType.OBJECT && data.depth !== 0 && data.parent.type === ItemType.ARRAY) {
          context.emit("selected", {
            key: data.key,
            path: `${path.replace(/@#$/, '')}`,
            value: "",
          });
        } else if (data.depth === 0 || data.type === ItemType.OBJECT) {
          context.emit("selected", {
            key: data.key,
            path: "/#",
            value: "",
          });
        }
      }
    }

    function setClass(tab) {
      if (tab === 5) {
        return "tag user-variable-tag";
      } else if (tab === 6) {
        return "tag system-variable-tag";
      } else if (tab === 7) {
        return "tag metaoutput-tag";
      } else {
        return "tag output-tag";
      }
    }

    function removeAtFromPath(path: string) {
      if (path.length > 1 && path.charAt(1) === "@") {
        return path.slice(0, 1) + path.slice(2);
      }
      return path;
    }

    function onClick(data: Data): void {
      context.emit("selected", {
        key: data.key,
        value: data.value,
        path: data.path,
      } as SelectedData);
    }

    function bubbleSelected(data: Data): void {
      context.emit("selected", data);
    }

    function getKey(itemData: ItemData): string {
      const keyValue = Number(itemData.key);
      return !isNaN(keyValue) ? `${itemData.key}":` : `"${itemData.key}":`;
    }

    function getValueColor(value: ValueTypes): string {
      return when(typeof value)
          .is((v) => v === "string", then("var(--jtv-string-color)"))
          .is((v) => v === "number", then("var(--jtv-number-color)"))
          .is((v) => v === "bigint", then("var(--jtv-number-color)"))
          .is((v) => v === "boolean", then("var(--jtv-boolean-color)"))
          .is((v) => v === "object", then("var(--jtv-null-color)"))
          .is((v) => v === "undefined", then("var(--jtv-null-color)"))
          .default(then("var(--jtv-valueKey-color)"));
    }

    function getKeyColor(path: string): string {
      if (props.tab === 1) {
        if (props.path === path) {
          return "var(--jtv-selectedKey-color) !important";
        } else {
          return (props.objectMap.has(path)) ? props.objectMap.get(path).color : "";
        }
      } else {
        let check = false;
        let color = "";
        props.objectMap.forEach((mapVal, mapKey) => {
          if (mapVal.value === path) {
            check = true;
            color = mapVal.color;
          }
        });
        return check ? color : "";
      }
    }

    function matchedElements(data) {
      if (props.tab === 1) {
        return props.element.key === data.path;
      } else {
        return props.element.value === data.path;
      }
    }

    const classes = computed((): unknown => ({
      "chevron-arrow": true,
      opened: state.open,
    }));
    const valueClasses = computed((): unknown => ({
      "value-key": true,
      "can-select": props.canSelect,
    }));

    const lengthString = computed((): string => {
      const { length } = props.data;
      if (props.data.type === ItemType.ARRAY) {
        return length === 1 ? `${length} item` : `${length} properties`;
      }
      return length === 1 ? `${length} item` : `${length} properties`;
    });

    const dataValue = computed((): string => (props.data.value === undefined
        ? "undefined"
        : JSON.stringify(props.data.value)));

    return {
      state,
      toggleOpen,
      onClick,
      bubbleSelected,
      getKey,
      getValueColor,
      classes,
      valueClasses,
      lengthString,
      dataValue,
      ItemType,
      getKeyColor,
      matchedElements,
      setClass,
    };
  },
});
